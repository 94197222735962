import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text } from 'theme-ui'

const PageCookiePolicy = props => {

  return (
    <Layout {...props}>
      <Seo title='Gizlilik Politikası' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Gizlilik Politikası'
          subheader=''
        />
      </Stack>
      <Stack>
        <Main>

          <Text variant='h4'>
            1- GENEL
          </Text>
          <Text variant='p'>
            Bu Websitesini ve içeriğindeki servisleri (“Hizmetler”) kullanıyor olmanız,
            (BlogBahane) Websitesi Gizlilik Koşullarını (“Gizlilik Koşulları”) ve kurallarını okuduğunuz ve kabul ettiğiniz anlamına gelir.
            Gizlilik Koşullarında öngörülen zorunlulukları yerine getiremeyeceğinizi düşünüyorsanız, bu Websitesi veya Hizmetlerini kullanmayınız.
            Websitesindeki bazı yerlerin kullanımı veya bu yerlerle kurulacak etkileşim hakkında özel birtakım ek hüküm ve şartlar geçerli olabilir.
          </Text>

          <Text variant='h4'>
            2- GİZLİLİK KOŞULLARININ TAKİBİ/DEĞİŞİKLİK
          </Text>
          <Text variant='p'>
            "BlogBahane", herhangi bir zamanda önceden haber vermeksizin Gizlilik Koşullarında değişiklik yapma ya da ek koşullar getirme hakkına sahiptir.
            Kullanım sırasında toplanan bilgilerin niteliklerinden, nasıl kullanıldığından, bu bilgilerin Üçüncü Şahıslar ile hangi durumlarda paylaşıldığından
            ve gerekli tüm gizlilik koşullarından haberdar olunması için yapılan değişiklikler bu sayfada sunulacaktır. "BlogBahane"in, Gizlilik Koşullarında
            değişiklik yapma hakkı saklı olduğundan, Gizlilik Koşullarının düzenli olarak takibi ve okunması Kullanıcının sorumluluğundadır. Bu Websitesi ve
            Hizmetlerini, yapılabilecek bu tarz bir değişiklikten sonraki kullanım, Gizlilik Koşullarındaki değişiklilerin kabul edildiği anlamını taşır.
          </Text>

          <Text variant='h4'>
            3- AÇIK SİSTEM
          </Text>
          <Text variant='p'>
            Kullanıcılar; internet ortamının güvenilir bir ortam olmadığını, internet ortamında iletişimin riskli olduğunu, kişisel bilgiler, şifreler vb.
            de dâhil her türlü bilginin Üçüncü Şahısların hukuka aykırı fiillerinin muhatabı olabileceğini bilmekte ve kabul etmektedir. "BlogBahane", Websitesinin
            yüzde yüz güvenli olduğu yönünde veya kötü niyetli hareketlere karşı tamamen koruma sağlandığı konusunda hiçbir garanti vermemektedir.
          </Text>

          <Text variant='h4'>
            4- KULLANICI BİLGİLERİ
          </Text>
          <Text variant='p'>
            Kullanıcı tarafından Websitesi ve Hizmetlere yerleştirilen, iletilen veya bu Websitesi ve Hizmetler aracılığıyla gönderilen her türlü içerikten
            ve kişisel bilgiden (her türlü yazı, doküman, müzik, ilan, reklâm, iletilen görüş ve düşünceler dâhil fakat bunlarla sınırlı olmamak üzere,
            ses veya yazı veya hareketli/hareketsiz görüntü içeren her türlü içerik) kullanıcı sorumludur. Kullanıcılar, Websitesi ve Hizmetlere
            ilettikleri/gönderdikleri her türlü bilginin güvenilirliğini, doğruluğunu, Üçüncü Şahısların haklarını ihlal etmediğini, yasalara aykırı
            olmadığını beyan ve garanti etmiştir. "BlogBahane"in, Kullanıcılar tarafından web sitesine konulan, diğer Kullanıcılara veya Üçüncü Şahıslara herhangi
            bir zarar veya yük getirdiğine inandığı içeriği veya mevzuata, uluslararası hukuk belgelerine aykırı içeriği tamamen veya kısmen yayından kaldırma
            ya da erişimi engelleme hakkı saklıdır. Ancak bu yönde herhangi bir yükümlülüğü bulunmamaktadır.
          </Text>

          <Text variant='h4'>
            5- KULLANICI ADI VE ŞİFRE
          </Text>
          <Text variant='p'>
            "BlogBahane" bazı Hizmet ve bölümlerin kullanımını Üyelik/Kayıt şartına bağlı tutabilir. Kayıt sırasında Kullanıcıya ad ve şifre verilebilir ya da Kullanıcı
            tarafından ad ve şifre oluşturulması istenebilir. Kullanıcı adının ve şifrenin muhafazasından, yanlış ya da hukuka aykırı kullanımından ve olası tüm
            izinsiz kullanımlarından doğrudan Kullanıcı sorumludur. Girişi üyelik koşuluna bağlı Hizmetlere ancak şifre ile giriş yapılabilir. Şifrenin özel
            rakamlar, harfler ve karakterler kullanılarak daha güvenli bir biçimde oluşturulmasından ya da bunun için gerekli değişikliklerin yapılmasından
            Kullanıcı sorumludur. Üyelik adı ve şifre kullanılarak yapılan tüm işlemlerin sorumluluğu Kullanıcıya aittir. Üye adı ve şifrenin kaybedilmesi,
            yetkisiz Üçüncü Şahısların eline geçmesi ya da Üye güvenliğini tehdit eden bir durumla karşılaşılması halinde derhal "BlogBahane"e haber verilir.
          </Text>

          <Text variant='h4'>
            6- BİLGİLERİN KORUNMASI
          </Text>
          <Text variant='p'>
            "BlogBahane", Websitesindeki tüm sayfaların güvenliği için azami gayret göstermektedir. Websitesinde kayıtlı bulunan verilerin gizliliğini,
            güvenliğini ve bütünlüğünü korumak için çok çeşitli teknik ve yönetimsel uygulamalardan yararlanılmaktadır.
          </Text>

          <Text variant='h4'>
            7- ÜÇÜNCÜ KİŞİLERE AİT SİTELER
          </Text>
          <Text variant='p'>
            Bu Websitesinde, "BlogBahane"in işletmediği veya işletimini kontrol etmediği, Üçüncü Şahıslar tarafından işletilen alt ve üst web siteleri
            bulunabilir ve bunlara bağlantı sağlanabilir/link/bilgi verilebilir. "BlogBahane"in erişim sağlanan bu web sitelere ilişkin, içerik, güvenlik,
            gizlilik politikaları ve iletişimin sürekli sağlanacağına dair herhangi bir garantisi ya da özel bir taahhüdü yoktur. Sorumluluk, Üçüncü Şahıslara
            ait sitelerde yazılı koşulara tabidir. Herhangi bir işlem yapılmadan önce söz konusu sitelerde yer alan güvenlik ve gizlilik koşulları okunmalıdır.
            Söz konusu sitelere sağlanan kişisel bilgilerden, bu sitelerden istifade edilen içerikten ve servislerden veya bu sitelerin gizlilik politika ve
            uygulamalarından "BlogBahane" sorumlu tutulamaz.
          </Text>

          <Text variant='h4'>
            8- WEBSİTESİ YARDIMCI PROGRAMLARI
          </Text>
          <Text variant='p'>
            Kullanıcının Websitesi tarafından sunulan bazı Hizmetlerden veya Websitesinden yararlanabilmesi için yardımcı programlar kullanılması gerekebilir.
            Bu Hizmetlerden/Websitesinden yararlanıldığı takdirde, Hizmetten yararlanma biçim ve kapsamıyla ilgili veriler, Websitesi veritabanına kaydedilebilir.
            Keza Hizmetlerden daha kolay yararlanabilmeniz için bazı “çerez”lerden yararlanılabilir, bunlar vasıtasıyla Kullanıcıya bazı bilgiler gönderilebilir.
          </Text>

          <Text variant='h4'>
            9- KİŞİSEL BİLGİLER
          </Text>
          <Text as='ul'>
            <li>Websitesinin ziyaret edilmesi, kullanılması ya da herhangi bir Hizmetten yararlanılması için ad, soyad, adres/yaşanılan yer, IP bilgileri,
              e-posta adresi, telefon numarası v.b. kişisel bilgiler Websitesine verilmiş, kaydedilmiş olur/olabilir. Kullanıcı, vermiş/açıklamış/kaydetmiş
              olduğu kişisel bilgilerin "BlogBahane"e açık olacağını dikkate alarak bu bilgileri açıklamış olduğunu ve Websitesine açıkladığı, kaydettiği her bilginin,
              içeriğin "BlogBahane"e karşı gizliliğini yitirdiğini kabul etmiş sayılır. Kullanıcı, kişisel bilgilerini diğer kullanıcılarla veya Üçüncü Şahıslara
              paylaşmayı seçerse, kaydettiği/ilettiği her bilgi ve içeriğin diğer kullanıcılara ve Üçüncü Şahıslara karşı da gizliliğini yitirdiğini kabul etmiş
              sayılır.</li>
            <li>"BlogBahane", Kullanıcı tarafından verilmiş olan bilgileri ve kaydedilen içeriği veritabanlarında tasnif edip saklayabilir, Websitesinin, "BlogBahane"in
              faaliyet alanındaki işlerin ve Hizmetlerin amaçlarıyla uyumlu olarak kullanabilir, işleyebilir, mevzuata uygun olarak yurt içine ya da yurt dışına
              transfer edebilir, veritabanındaki verilerden dilediği gibi yararlanabilir. Kullanıcı bu konuda "BlogBahane"e açıkça izin vermiştir. "BlogBahane",
              Websitesi aktivite bilgilerinden, erişim araçlarından ve browser bilgilerinden de dilediği gibi yararlanma hakkına sahiptir.</li>
            <li>Hassas Bilgiler: "BlogBahane", Kullanıcılardan yasalar gerektirmediği sürece herhangi bir şekilde ve kesinlikle; ırk ve etnik kimlik,
              dini/siyasi/felsefi düşünce, fiziksel ya da ruhsal sağlık ve özellikler gibi hassas bir bilgi talep etmemektedir. Bu bilgilerin verilmiş,
              kaydedilmiş olmasından dolayı "BlogBahane"in bir sorumluluğu yoktur.</li>
          </Text>
          <Text variant='p'>
            Kişisel verilerinizle ilgili olarak verdiğiniz izin, size hizmet vermek; Hizmetlerin, "BlogBahane"in, üyelerin, Üçüncü Şahısların tanıtım faaliyetleri;
            hukuki gereklilikler vb. hallerde kullanma iznini de içermektedir.
          </Text>

          <Text variant='h4'>
            10- GİZLİLİK KOŞULLARININ İHLALİ
          </Text>
          <Text variant='p'>
            "BlogBahane", Gizlilik Koşullarına uyulmaması ya da kuralları ihlal etme girişiminde bulunulması durumunda, ihlal tam olarak gerçekleşsin ya da
            gerçekleşmesin, sistemde mevcut bilgileri reddetme, içerikten çıkarma, silme hakkıyla birlikte önceden haber vermeksizin Kullanıcıların Websitesi
            ve Hizmetlere erişimini askıya alma, sonlandırma, üyeliği iptal etme hakkını (zorunlu olmamakla birlikte) saklı tutar. Bu kural, koşulların
            Kullanıcı tarafından dolaylı ihlali ya da ihlal girişimi olması durumu için de geçerlidir.
          </Text>

          <Text variant='h4'>
            11- BİLGİ VE İLETİŞİM
          </Text>
          <Text variant='p'>
            Gizlilik Politikası ile ilgili tüm soru ve görüşlerinizi iletmek için bize ulaşın!
          </Text>

          <Text variant='p'>
            https://www.blogbahane.com/iletisim
          </Text>

        </Main>
      </Stack>
    </Layout>
  )
}

export default PageCookiePolicy
